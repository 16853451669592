// * DEPENDENCIES
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../../context/AppContextProvider';
import GridIcon from "@rsuite/icons/Grid";

// * COMPONENTS
import ContainerViews from "../../components/ContainerViews";
import Navbar from "../../components/shared/NavBarItems";
import IndexListado from "./Listado/IndexListado";


const Index = ({ actions }) => {
    // * USE STATES
    // Todo: Variables
    const { usuario } = useContext(AppContext);
    const [active, setActive] = useState('TABLISTADO');
    const [keys, setKeys] = useState({
        data: {},
        module: ""
    });

    const defaultOptions = [
        {
            act: "TABLISTADO",
            key: "TABLISTADO",
            name: "Registros",
            disabled: false,
            icon: <GridIcon />,
        },

    ]
    const [options, setOptions] = useState([]);

    // * USE EFFECTS
    useEffect(() => {
        let tabs = [];
        defaultOptions.forEach((item) => {
            let existe = actions.find((act) => act.IDNTFCDR == item.act
                || defaultOptions.length == 0
            );
            if (existe || item.act == null) tabs.push(item);
        });

        setOptions(defaultOptions);
    }, []);

    const ListComponents = {
        TABLISTADO: (<IndexListado usuario={usuario} setActive={setActive} keys={keys} setKeys={setKeys} />),
    };

    return (
        <>
            <ContainerViews>
                <Navbar
                    appearance="tabs"
                    active={active}
                    onSelect={setActive}
                    itemsNav={options}
                />

                {ListComponents[active]}

            </ContainerViews>
        </>
    );
};
export default Index;
