import React from "react";

const ContainerViews = ({ children }) => {
  return (
    <>
      <div className="relative max-w-screen-xl m-auto py-16">
        {children}
      </div>
    </>
  );
};

export default ContainerViews;