import { Navigate } from 'react-router-dom';
import Index from '../views/Inventario/Index';
import constants from '../core/constants';
import inventarioBreadcrums from '../breadcrums/inventario.breadcrums';

const TITLE = "Inventario";
const Inventario = [
    {
        path: `${constants.CARPETA_DESPLIEGUE}inventario`,
        element: <Index />,
        pathTo: `inventario`,
        breadcrums: {
            TITLE,
            breadcrums: inventarioBreadcrums.inventarioBC(),
        }
    },
]

export default Inventario