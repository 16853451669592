import axiosService from '../services/axios.service';
import authserver from '../services/auto.service';

const url = `${authserver.getApiUrlView("login-web")}`;

const ListarModulos = (formValue) => {
    var cadena = `${url}modulos/listar-modulos`
    return axiosService.getParams(cadena, formValue)
}

const ListarSubModulos = (formValue) => {
    var cadena = `${url}modulos/listar-submodulos`
    return axiosService.getParams(cadena, formValue)
}

export default {
    ListarModulos,
    ListarSubModulos
}