const createWebpImage = (filesImage, setFormValue, formValue) => {
    let src = URL.createObjectURL(filesImage);
    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');
    let userImage = new Image();
    userImage.src = src;
    userImage.onload = () => {
        URL.revokeObjectURL(src)
        canvas.width = userImage.width;
        canvas.height = userImage.height;
        ctx.drawImage(userImage, 0, 0);
        let webpImage = canvas.toDataURL('image/webp', .1);
        setFormValue({ ...formValue, IMGPRSNA: webpImage });
    }
}

export { createWebpImage } 