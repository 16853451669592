// * DEPENDENCIES
import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Loader, Toggle } from 'rsuite';
import { toast } from 'react-toastify';

// * COMPONENTS
import Accepters from "../../../components/shared/Accepters";
import FormControlCustom from '../../../components/shared/FormControlCustom';
import CustomTable from "../../../components/customs/CustomTable";
import IconButtonCustom from "../../../components/shared/IconButtonCustom";

// * FUNCTIONS
import inventarioProxy from "../../../proxy/inventarioProxy";
import columns from '../../../components/columnsHeader/productosTB';
import useToast from "../../../components/hooks/useToast";
import userService from "../../../services/user.service";

// * MODALES
import ModalAgregarProducto from "../Modales/ModalAgregarProducto";
import ModalEditarProducto from "../Modales/ModalEditarProducto";
import ModalEliminar from "../../../components/shared/ModalEliminar";
import useModal from "../../../components/hooks/useModal";

const IndexListado = ({
    usuario,
    active,
    setActive,
    setKeys,
}) => {

    const defaultValues = {
        ROWS: 10,
        INIT: 0,
        NMBRE: "",
        STOCK: null
    }
    // * USE STATES
    const formRef = useRef();
    // Todo: MODAL
    const [openModalInsertar, toogleModalInsertar] = useModal();
    const [openModalEditar, toogleModalEditar] = useModal();
    const [openModalEliminar, toogleModalEliminar] = useModal();
    const [rowData, setRowData] = useState({})
    // const [action, setAction] = useState(false)

    // Todo: Variables
    const [formValue, setFormValue] = useState(defaultValues);
    const [formError, setFormError] = useState({});
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const { showToast, updateToast, action } = useToast();

    // Todo: Data
    const [productos, setProductos] = useState([]);

    // * FUNCTIONS

    const CargarTabla = (actions = true) => {
        setLoading(true);
        inventarioProxy.ListarProductos(actions ? {
            ...formValue
        } : defaultValues)
            .then((res) => {
                setTotalRows(res?.data?.[0]?.totalrows)
                setProductos(res.data);
            })
            .catch((err) => toast.error("Error al cargar registros."))
            .finally(() => setLoading(false));
    }

    const handleDatos = (row, isEdit = true) => {
        setRowData(row);
        if (isEdit) toogleModalEditar();
        else toogleModalEliminar();
    }

    const handleEliminar = () => {
        showToast('Cambiado estado, espere por favor...', 'info');
        inventarioProxy.EliminarProducto({
            ID: rowData.id,
        })
            .then((res) => {
                if (res.data.codEstado > 0) {
                    toogleModalEliminar();
                    CargarTabla(false)
                }

                updateToast(
                    res.data.codEstado === -1 ? res?.data?.mensaje : 'Se cambió el estado correctamente.',
                    res.data.codEstado === -1 ? 'error' : 'success'
                );

            })
            .catch((err) => updateToast('Error al cambiar estado del registro.', 'error'))
    }

    const renderColumns = () => {
        columns.productosTB = columns.productosTB.filter(element => element.name !== 'Opciones');

        columns.productosTB.push({
            name: "Opciones",
            cell: (row) => {
                return (
                    <div className='items-center'
                        style={{ width: '100%', textAlign: 'center' }}>
                        <IconButtonCustom TooltipMsg="Editar"
                            className="btnUpdate"
                            icon="fa-solid fa-pencil"
                            onClick={() => handleDatos(row)}
                        />

                        <IconButtonCustom TooltipMsg="Eliminar"
                            className="btnDelete"
                            icon="fa-solid fa-refresh"
                            onClick={() => handleDatos(row, false)}
                        />
                    </div>
                );
            },
        });


        return columns.productosTB;
    }

    return (
        <>
            <Form
                ref={formRef}
                checkTrigger="change"
                onCheck={setFormError}
                autoComplete="off"
                formValue={formValue}
                onChange={setFormValue}
                className="grid grid-cols-1 md:grid-cols-4 gap-4"
            >

                <FormControlCustom
                    className="col-span-12 md:col-span-1"
                    label="Categoria de Producto"
                    value={formValue?.GDCTGRIA ? formValue.GDCTGRIA : ""}
                    isRequired={false}
                    name="GDCTGRIA"
                    data={userService.getObtenerGD("GDCTGRIA")}
                    accepter={Accepters.SearchableSelectPicker}
                />

                <FormControlCustom
                    className="col-span-12 md:col-span-2"
                    label="Producto"
                    value={formValue.NMBRE}
                    isRequired={false}
                    name="NMBRE"
                    accepter={Accepters.TextInput}
                />

                <FormControlCustom
                    className="col-span-12 md:col-span-1"
                    label="Stock"
                    value={formValue.STOCK}
                    isRequired={false}
                    name="STOCK"
                    min={0}
                    accepter={Accepters.Number}
                />

                <div className="col-span-12 md:col-span-12 flex justify-end md:items-center items-end gap-4 mt-5">
                    <Button appearance="primary"
                        className='rs-btn btnPrimary'
                        onClick={() => CargarTabla()}
                        style={{ marginBottom: '10px' }} >
                        <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" className='mr-2' />
                        Buscar
                    </Button>

                    <Button
                        onClick={() => toogleModalInsertar()}
                        className='rs-btn btnSecondary' style={{ marginBottom: '10px' }}>
                        <FontAwesomeIcon icon="fa-solid fa-plus" className='mr-2' />
                        Nuevo
                    </Button>
                </div>

            </Form>

            <CustomTable
                columns={renderColumns()}
                data={productos}
                onChange={CargarTabla}
                formValue={formValue}
                setFormValue={setFormValue}
                progressPending={loading}
                totalrows={totalRows}
            />

            <ModalAgregarProducto
                openModalInsertar={openModalInsertar}
                toogleModalInsertar={toogleModalInsertar}
                reload={() => { CargarTabla(false) }}
                Accepters={Accepters}
            />

            <ModalEditarProducto
                openModalEditar={openModalEditar}
                toogleModalEditar={toogleModalEditar}
                reload={() => { CargarTabla(false) }}
                Accepters={Accepters}
                usuario={usuario}
                data={rowData}
            />


            <ModalEliminar
                openModalEliminar={openModalEliminar}
                toogleModalEliminar={toogleModalEliminar}
                onDelete={handleEliminar}
                action={action}
            />
        </>
    );
};

export default IndexListado