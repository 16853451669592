import auditoriaTB from './auditoriaTB';

const productosTB =
    [
        {
            name: '',
            width: '70px',
            center: true,
            cell: row => <div style={{ width: '100%', textAlign: 'center' }}>{row.rn}</div>,
        },
        {
            name: 'Producto',
            width: '300px',
            cell: row => <div style={{ width: '100%', textAlign: 'left' }}>{row.nmbre}</div>,
        },
        {
            name: 'Precio',
            width: '100px',
            cell: row => <div style={{ width: '100%', textAlign: 'center' }}>{row.price}</div>,
        },
        {
            name: 'Stock',
            width: '100px',
            cell: row => <div style={{ width: '100%', textAlign: 'center' }}>{row.stock}</div>,
        },
        {
            name: 'Disponible',
            width: '100px',
            cell: row => <div style={{ width: '100%', textAlign: 'center' }}>{
                row.stock > 0 ? 'SI' : 'NO'
            }</div>,
        },
        ...auditoriaTB
    ]


export default {
    productosTB
};