import { Checkbox } from 'rsuite';
import ErrorMessage from './ErrorMessage';

const Checkoud = ({ disabled = false, checked, name, onChange, fieldError, isRequired, text='Respuesta Correcta' }) => {
    const handleChangeName = (value, checked) => {
        onChange(checked);
    };

    if (isRequired) {
        return (<>
            <Checkbox disabled={disabled} checked={checked} onChange={handleChangeName}
                name={name} className={(fieldError ? " form-input-error " : "")} placeholder="0">
                {text}
            </Checkbox>
            {fieldError ? <ErrorMessage>{fieldError}</ErrorMessage> : null}
        </>)
    }else{
        return (<>
            <Checkbox disabled={disabled} checked={checked} onChange={handleChangeName}
                name={name} className={(fieldError ? " form-input-error " : "")} placeholder="0">
                {text}        
            </Checkbox>
        </>)
    }
};

export {
    Checkoud,
};