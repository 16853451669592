import userService from './user.service'
import globalProxy from '../proxy/globalProxy';
import jwt_decode from "jwt-decode";

const obtenerValidarToken = async () => {
    const expireTk = await decode_token(userService.getExpireTk());
    if (!expireTk) {
        const expireTkRefresh = await decode_token(userService.getExpireTkRefresh());

        if (!expireTkRefresh) {
            alert("se vencio el token")
            return { 'Content-Type': 'application/json' }
        }

        const respuesta = await globalProxy.UpdateTokenRefresh(userService.getUserAccessToken(), userService.getUserRefreshToken());
        localStorage.setItem('accessToken', respuesta?.data?.accesstoken);
        localStorage.setItem('refreshToken', respuesta?.data?.refreshtoken);
        const decoded = await jwt_decode(respuesta?.data?.accesstoken);
        const decodedRefresh = await jwt_decode(respuesta?.data?.refreshtoken);
        localStorage.setItem('expireTk', decoded?.exp);
        localStorage.setItem('expireTkRefresh', decodedRefresh?.exp);
        
        return {
            Authorization: 'Bearer ' + userService.getUserRefreshToken(),
            'Content-Type': 'application/json'
        }
    }

    return {
        Authorization: 'Bearer ' + userService.getUserAccessToken(),
        'Content-Type': 'application/json'
    }

}

const decode_token = async (tiempo) => {
    if (tiempo == null) return false
    if (tiempo < new Date().getTime() / 1000) return false
    else return true
}


export default obtenerValidarToken;