
const ERROR_MESSAGE =  {
    Required: "Campo Requerido",
    MinLength: "Debe ingresar almenos xx caracteres",
    MaxLength: "Máximo xx caracteres",
    NumberMaxLength: "Máximo xx dígitos",
    Numeric: "Sólo ingrese números",
    TextOnly: "Sólo ingrese texto",
    Alphanumeric: "Sólo ingrese letras o números",
    Email: "Ingrese un correo válido",
}


const ERROR = (llave, max) => {
    let cadena = ERROR_MESSAGE?.[llave] || "";
    if(["NumberMaxLength", "MaxLength", "MinLength"].includes(llave)) {
        cadena = cadena.replace("xx", max);
    }
    return cadena;
}


export default {
    ERROR,
}