import { Toggle } from 'rsuite';
import ErrorMessage from './ErrorMessage';

const ToogleGroupWithError = ({ value = false, onChangeBool, name, fieldError, isRequired }) => {

    if (isRequired) {
        return (
            <>
                <Toggle
                    checked={value}
                    onChange={(e) => { onChangeBool(e) }}
                    name={name}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                />
                {fieldError ? <ErrorMessage>{fieldError}</ErrorMessage> : null}

            </>
        )
    } else {
        return (
            <>
                <Toggle
                    checked={value}
                    onChange={(e) => { onChangeBool(e) }}
                    name={name}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                />
            </>
        )
    }
};

export {
    ToogleGroupWithError,
};