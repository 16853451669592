import axios from 'axios';
import authserver from '../services/auto.service';

const url = `${authserver.getSegUrl()}`;

const UpdateTokenRefresh = (token, refreshToken) => {
    var cadena = `${url}refresk-token?accessToken=${token}&refreshToken=${refreshToken}`
    return axios.get(cadena);
}


export default {
    UpdateTokenRefresh
}