import constants from "../core/constants";

const inventarioBC = (active = false) => [
    {
        id: "1",
        url: active ? `/${constants.CARPETA_DESPLIEGUE}inventario` : null,
        name: "Listado"
    },
]

// const detalleGrupoDatoBC = (active = false) => [
//     ...grupoDatosBC(true),
//     {
//         id: "2",
//         url: active ? `/${constants.CARPETA_DESPLIEGUE}/grupo-dato/detalle` : null,
//         name: "Detalle"
//     },
// ]

export default { inventarioBC };