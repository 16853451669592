// * DEPENDENCIES
import React, { useEffect, useState, useRef } from "react";
import { Form, InputNumber } from 'rsuite';

// * COMPONENTS
import CustomModal from '../../../components/customs/CustomModal';
import FormControlCustom from '../../../components/shared/FormControlCustom';

// * FUNCTIONS
import useToast from "../../../components/hooks/useToast";
import inventarioProxy from "../../../proxy/inventarioProxy";
import userService from "../../../services/user.service";
import { ValidarEditarProducto } from "../schemaModel";
import { createWebpImage } from "../../../components/helpers/formtImage";
import { toast } from "react-toastify";

const ModalEditarProducto = ({
    openModalEditar, toogleModalEditar, reload, Accepters, usuario, data
}) => {

    // * VARIABLES
    const formRef = useRef();

    // * USE STATE
    const [formValue, setFormValue] = useState({})
    const [formError, setFormError] = useState({});
    const { showToast, updateToast, action } = useToast();
    const [isImg, setIsImg] = useState(false);

    // * USE EFFECT
    useEffect(() => {
        if (openModalEditar) {
            setFormValue({});
            setFormError({});
            document.getElementById("IMGPRSNA").value = "";          

            if (isNaN(data.id) || data.id === undefined) {
                toast.error("Error al obtener el producto, intente nuevamente.");
                toogleModalEditar();
                return;
            }

            showToast("Obteniendo producto...", "info")
            inventarioProxy.ObtenerProducto({ ROWS:1, INIT: 0, ID: data.id })
                .then((res) => {
                        console.log(res)
                    if(res.status == 200) {
                        setFormValue({
                            ID: res.data?.id,
                            NMBRE: res.data?.nmbre,
                            GDCTGRIA: res.data?.gdctgria,
                            PRICE: res.data?.price,
                            STOCK: res.data?.stock,
                            DSCRPCN: res.data?.dscrpcn,
                            IMGPRSNA: res.data?.imgen
                        });
                        
                        setIsImg(true);
                    }
                    updateToast(
                        res.status === 200 ? "Producto obtenido correctamente." : "Error al obtener el producto.",
                        res.status === 200 ? 'success' : 'error'
                    )
                })
                .catch((err) => updateToast('Error al obtener el producto.', 'error'))
        }
    }, [openModalEditar])

    // * FUNCTIONS
    const handleSubmit = async () => {
        if (!formRef.current.check()) return;

        showToast('Guardando, espere por favor...', "info");

        const formatData = {
            ...formValue,
            IMGEN: formValue.IMGPRSNA,
            PRICE: parseFloat(formValue.PRICE),
        };

        inventarioProxy.ActualizarProducto(formatData)
            .then((res) => {
                if (res.data.codEstado > 0) {
                    toogleModalEditar();
                    reload()
                }

                updateToast(
                    res.data.codEstado === -1 ? res?.data?.mensaje : "Se edito correctamente.",
                    res.data.codEstado === -1 ? 'error' : 'success'
                );

            })
            .catch((err) => updateToast('Error al editar el registro.', 'error'))
    }

    const uploadImage = (filesImage) => {
        setFormValue({ ...formValue, IMGPRSNA: "" })
        setIsImg(false);

        if (filesImage) {
            if (!['image/png', 'image/jpeg', 'image/webp'].includes(filesImage.type)) {
                toast.error("Solo se permiten archivos de tipo imagen.");
                return
            }

            createWebpImage(filesImage, setFormValue, formValue);
            setIsImg(true);
        }

    }

    return (
        <>
            <CustomModal
                openModal={openModalEditar}
                toogleModal={toogleModalEditar}
                formId={'frmEditarProducto'}
                title={'Editar Producto'}
                size="md"
                action={action}
                handleSubmit={handleSubmit}
            >
                <Form
                    ref={formRef}
                    checkTrigger="change"
                    formValue={formValue}
                    onChange={setFormValue}
                    onCheck={setFormError}
                    autoComplete="off"
                    model={ValidarEditarProducto}
                    id='frmEditarProducto'
                >

                    <div className="grid grid-cols-12 gap-x-4 mb-3 gap-y-3">

                        <div className="col-span-12">
                            <FormControlCustom
                                rowError={formError.NMBRE}
                                label="Producto"
                                value={formValue?.NMBRE ? formValue.NMBRE : ""}
                                isRequired={true}
                                name="NMBRE"
                                accepter={Accepters.TextInput}
                            />
                        </div>

                        <div className="col-span-12 md:col-span-4">
                            <FormControlCustom
                                label="Categoria de Producto"
                                value={formValue?.GDCTGRIA ? formValue.GDCTGRIA : ""}
                                isRequired={true}
                                rowError={formError.GDCTGRIA}
                                name="GDCTGRIA"
                                data={userService.getObtenerGD("GDCTGRIA")}
                                accepter={Accepters.SearchableSelectPicker}
                            />
                        </div>

                        <div className="col-span-12 md:col-span-4">
                            <span className="text-sm text-gray-500 dark:text-gray-400 mb-2">Precio
                                <span className="text-red-500 dark:text-red-400"> *</span>
                            </span>
                            <InputNumber
                                className={"w-full text-sm choose text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer "
                                    + " dark:text-gray-400 focus:outline-none "
                                    + "dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                    + (formError?.PRICE ? " form-input-error " : "")
                                }
                                value={formValue?.PRICE ? formValue.PRICE : ""}
                                onChange={(value) => setFormValue({ ...formValue, PRICE: value })}
                            />
                        </div>

                        <div className="col-span-12 md:col-span-4">
                            <FormControlCustom
                                rowError={formError.STOCK}
                                label="Stock"
                                value={formValue?.STOCK ? formValue.STOCK : ""}
                                isRequired={true}
                                name="STOCK"
                                accepter={Accepters.Number}
                            />
                        </div>

                        <div className="col-span-6">
                            <FormControlCustom
                                rowError={formError.DSCRPCN}
                                label="Descripción del Producto"
                                isMayus={false}
                                value={formValue?.DSCRPCN ? formValue.DSCRPCN : ""}
                                isRequired={true}
                                name="DSCRPCN"
                                accepter={Accepters.TextArea}
                            />
                        </div>

                        <div className="col-span-6 items-center cursor-pointer" >
                            <span className="text-sm text-gray-500 dark:text-gray-400 mb-2">Imagen del Producto
                                <span className="text-red-500 dark:text-red-400"> *</span>
                            </span>
                            {!isImg ? (
                                <>
                                    <label
                                        className="flex flex-col items-center justify-center w-full h-56 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                            <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                    strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                            </svg>

                                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Subir</span> imagen</p>
                                            <p className="text-xs text-gray-500 dark:text-gray-400">PNG, JPG</p>
                                        </div>

                                    </label>
                                </>
                            ) :
                                <img
                                    className="flex flex-col items-center justify-center w-full h-56 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                                    src={formValue.IMGPRSNA}
                                />
                            }
                            <input type="file"
                                className={"mt-2 text-sm choose text-gray-900 w-full bg-gray-50 rounded-lg border border-gray-300 cursor-pointer "
                                    + " dark:text-gray-400 focus:outline-none "
                                    + "dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                    + (formError.IMGPRSNA ? " form-input-error " : "")
                                }
                                id="IMGPRSNA"
                                accept="image/png, image/jpeg, image/webp"
                                onChange={(e) => uploadImage(e.target.files[0])}

                            />
                        </div>

                    </div>

                </Form>
            </CustomModal>

        </>
    )
}

export default ModalEditarProducto