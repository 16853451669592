import axiosService from '../services/axios.service';
import authserver from '../services/auto.service';

const url = `${authserver.getApiUrlView("login-web")}`;

const obtenerSelected = async (formValue = "") => {
    var cadena = `${url}modulos/listar-detalle-grupoDato`
    return await axiosService.getParams(cadena, formValue)
}
export default {
    obtenerSelected
}