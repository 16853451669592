import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import authserver from '../services/auto.service';
import { toast } from "react-toastify";

const Dropdown = () => {
  const [showOptions, setShowOptions] = useState(false);

  const handleClick = () => {
    setShowOptions(!showOptions);
  };

  const closeSection = () => {
    localStorage.clear();
    window.location.href = `${authserver.getAuthUrl()}login-web`;
  }

  const updatePassword = () => {
    toast.info("No tiene permisos para realizar esta acción", {
      toastId: "updatePassword",
    })
  }

  const redirectModules = () => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    localStorage.clear();
    window.location.href = `${authserver.getAuthUrl()}login-web/inicio?accessToken=${accessToken}&refreshToken=${refreshToken}`;
  }

  return (
    <>
      <div className="relative pr-6">
        <div className="flex items-center justify-center w-full gap-2 border-l border-gray-300 pl-3">
          <button
            onClick={handleClick}
            type="button"
            className="bg-gray-200 cursor-pointer justify-center py-2 px-3 rounded-lg"
            id="menu-button"
            data-idhtml="user-full-name-login"
            aria-expanded="true"
            aria-haspopup="true"
          >
            <FontAwesomeIcon
              icon="fa-solid fa-angle-down"
              className="font-medium text-blue-600"
            />
          </button>
          <label>Hola, {localStorage?.getItem("username")?.split(" ")?.[0] || ""}</label> 
        </div>

        {/* Dropdown options */}
        <div
          className={`origin-top-right absolute right-0 w-64 rounded-md shadow-lg bg-white border z-30 mt-6 mr-6 ${showOptions ? "opacity-100 scale-100 visible" : "opacity-0 scale-95 invisible"
            } transform transition-all duration-300`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div
            className="py-4 flex flex-col items-start gap-y-4 px-4 z-50"
            role="none"
          >
            <div className="m-auto w-full">
              <p className="poppins text-center w-full pb-2">OPCIONES DE PERFIL</p>
              <hr className="w-full bg-gray-900" />
            </div>
            <div className="flex gap-4">
              <div className="bg-blue-100 flex justify-center items-center text-md poppins text-blue-500 rounded-full w-9 h-9"><FontAwesomeIcon icon="fa-solid fa-table-cells-large" /></div>
              <button
                className="text-gray-500 font-semibold poppins block text-sm cursor-pointer"
                role="menuitem"
                tabIndex="-1"
                id="menu-item-0"
                onClick={redirectModules}
              >
                Ir al indice de Módulos
              </button>
            </div>
            <div className="flex gap-4">
              <div className="bg-blue-100 flex justify-center items-center text-md poppins text-blue-500 rounded-full w-9 h-9"><FontAwesomeIcon icon="fa-solid fa-lock" /></div>
              <button
                className="text-gray-500 font-semibold poppins block text-sm cursor-pointer"
                role="menuitem"
                tabIndex="-1"
                id="menu-item-1"
                onClick={updatePassword}
              >
                Cambiar Contraseña
              </button>
            </div>
            <div className="flex gap-4">
              <div className="bg-blue-100 flex justify-center items-center text-md poppins text-blue-500 rounded-full w-9 h-9"><FontAwesomeIcon icon="fa-solid fa-right-from-bracket" /></div>
              <button
                className="text-gray-500 font-semibold poppins block text-sm opacity-80 cursor-pointer hover:opacity-100 hover:transition-all duration-300"
                role="menuitem"
                tabIndex="-1"
                id="menu-item-2"
                onClick={closeSection}
              >
                Cerrar Sesión
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dropdown;
