import { useEffect } from "react"
// import Error404I from '../../assets/img/404-icon.svg'
import BMSLOGO from '../../assets/img/logo-bms.png'
import authService from '../../services/auto.service'

const Error404 = ({
    setIsNoFound = () => { }
}) => {

    useEffect(() => {
        setIsNoFound(true);

        return () => {
            setIsNoFound(false);
        };
    }, [setIsNoFound]);

    return (
        <div className="container-error">
            <div className="container-error__card">
                <div className="container-error__card-content" style={{ marginTop: "80px" }}>
                    {/* <object className="container-error__card-icon container-error__card-icon-404" style={{ top: "-190px", width: "230px" }} data={Error404I} type="image/svg+xml"></object> */}
                    <h1 className="poppins line-height-0 container-error__card-cod">404</h1>
                    <span className="poppins container-error__card-text">No Hallado</span>
                </div>
                <label>¡Oops! Parece que la página
                    <span className="font-bold pl-1">
                        {window?.location?.pathname || ""}
                    </span> no existe.
                </label>
                <button
                    onClick={() => {
                        window.location.href = authService.getAuthUrl();
                    }}
                    className="btn-login-rediret poppins text-white font-semibold py-2 px-4 rounded -mt-6">
                    Volver a Iniciar Sesión
                </button>
                {/* <div className="container-error__footer">
                    <img className="container-error__footer-logo" src={BMSLOGO} />
                </div> */}
            </div>
        </div>
    );
}

export default Error404
