import { Navigate } from 'react-router-dom';
import HomeInicio from '../views/Home/Inicio';
import constants from '../core/constants';

const TITLE = "Inicio";
const Home = [
    {
        path: `${constants.CARPETA_DESPLIEGUE}inicio`,
        pathTo: `inicio`,
        element: <HomeInicio />,
        breadcrums: {
            TITLE,
            breadcrums: []
        }
    },
    {
        path: `${constants.CARPETA_DESPLIEGUE}connect`,
        pathTo: `inicio`,
        element: <Navigate to={`/${constants.CARPETA_DESPLIEGUE}inicio`}  />,
        breadcrums: {
            TITLE,
            breadcrums: []
        }
    }
]

export default Home