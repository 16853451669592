import React from 'react'

const Logo = ({letters=''}) => {
  return (
    <div className="flex items-center text-2xl text-gray-900 px-6">
      <div className='mr-4'>
        <img className="min-w-[35px] h-8" src={`${process.env.PUBLIC_URL}/logo-bms.png`} alt="logo" />
      </div>
        <span className={letters}>BM<b>SYSTEM</b></span>
    </div>
  )
}

export default Logo