import { Nav } from 'rsuite';

const StyleDefault = {
  fontWeight: "bold",
  padding: '10px 15px',
  transition: 'color .1s ease-out, background-color .5s ease-out',
  transitionProperty: 'color, background-color'
}

const StylesNavBar = {
  backgroundColor: '#7b888a ',
  color: 'white',
  borderRadius: '10px solid #000000 ',
  ...StyleDefault
}


const Navbar = ({ active, onSelect, itemsNav, ...props }) => {
  return (
    <Nav {...props} activeKey={active} onSelect={onSelect} style={{ marginBottom: 20 }}>

      {
        itemsNav.map((item, index) => {
          return (
            <Nav.Item key={index}
              eventKey={item.key}
              style={(active === item.key) ? StylesNavBar : StyleDefault}
              disabled={item.disabled}
              icon={item.icon}
              onSelect={item.onSelect ? item.onSelect : () => { }}
            >
              {item.name}
            </Nav.Item>
          );
        })
      }
    </Nav>
  );
};

export default Navbar;