import { useEffect } from "react"
// import Error401I from '../../assets/img/401-icon.svg'
import BMSLOGO from '../../assets/img/logo-bms.png'
import authService from "../../services/auto.service";
import constants from "../../core/constants";

const Error401 = ({
    setIsNoFound = () => { }
}) => {
    useEffect(() => {
        setIsNoFound(true);

        return () => {
            setIsNoFound(false);
        };
    }, [setIsNoFound]);

    return (
        <div className="container-error">
            <div className="container-error__card">
                <div className="container-error__card-content">
                    {/* <object className="container-error__card-icon" data={Error401I} width="100px" type="image/svg+xml"></object> */}
                    <h1 className="poppins line-height-0 container-error__card-cod">401</h1>
                    <span className="poppins container-error__card-text">No Autorizado</span>
                </div>
                <label>¡Oops! Parece que no tienes acceso a esta página. Asegúrate de
                    <button onClick={() => {
                        window.location.href = authService.getAuthUrl() + constants.CARPETA_LOGIN;
                    }}
                        className="button_link poppins">iniciar sesión</button> primero.</label>
                {/* <div className="container-error__footer">
                    <img className="container-error__footer-logo" src={BMSLOGO} />
                </div> */}
            </div>
        </div>
    );
}

export default Error401