import axios from "axios";
import obtenerValidarToken from './auth.header';
import constants from "../core/constants";
import authService from "../services/auto.service";
import { toast } from "react-toastify";


const rediretPages = {
    "no-autorizado": authService.getAuthUrl() + constants.CARPETA_LOGIN,
    "error": constants.CARPETA_DESPLIEGUE + "/error",
    "server-error": constants.CARPETA_DESPLIEGUE + "/server-error",
    "access-denied": constants.CARPETA_DESPLIEGUE + "/access-denied",
    "connect": constants.CARPETA_DESPLIEGUE + "/connect"
}

const obtenerToken = async () => {
    const config = await obtenerValidarToken();
    return config;
};

const configurarSolicitud = async (config) => {
    const headers = await obtenerToken();
    config.headers = { ...config.headers, ...headers };
    return config;
};

const manejarErrores = (error, redirectToPath) => {

    if (error?.response?.status == 401) {
        // toast.error("No autorizado", {
        //     toastId: "error401",
        // });

        // redirectToPath(rediretPages["no-autorizado"]);
    }

    if (error?.response?.status == 500) {
        toast.error("Error en el servidor", {
            toastId: "error500",
        });
    }

    return Promise.reject(error);
};

axios.interceptors.request.use(configurarSolicitud, error => Promise.reject(error));
axios.interceptors.response.use(response => response, error => manejarErrores(error, (path) => {
    if (window.location.pathname !== path) {
        window.location.href = path;
    }
}));

const get = async (url) => {
    return axios.get(url);
}

const post = async (url, form) => {
    return axios.post(url, form);
}

const getParams = async (url, form) => {
    return axios.get(url, {
        params: form,
    })
}


export default {
    get,
    post,
    getParams,
}