import { DatePicker, DateRangePicker } from 'rsuite';
import ErrorMessage from './ErrorMessage';

const { beforeToday, 
	afterToday
} = DateRangePicker

const Calendar = {
	sunday: 'Dom',
	monday: 'Lun',
	tuesday: 'Mar',
	wednesday: 'Mie',
	thursday: 'Jue',
	friday: 'Vie',
	saturday: 'Sab',
	today: "Hoy",
	yesterday: "Ayer"
}

// format="yyyy-MM"
const DatePickerWithError = (
	{ disabled = false, value = new Date(), name, onChange, fieldError,
		isRequired, format = "dd-MM-yyyy", disabledDate = false }) => {
	if (isRequired) {
		return (<>
			{format == "dd-MM-yyyy" ? (
				<>
					<DatePicker disabled={disabled}
						// disabledDateMin es true, blloquear fechas posteriores a hoy
						disabledDate={disabledDate ? afterToday() : null}
						locale={Calendar}
						placeholder="Fecha" oneTap className={(fieldError ? " form-input-error " : "")} name={name}
						value={value} format={format} onChange={onChange} />
					{fieldError ? <ErrorMessage>{fieldError}</ErrorMessage> : null}
				</>
			) : (
				<>
					<DatePicker disabled={disabled}
						disabledDate={disabledDate ? afterToday() : null}
						locale={Calendar}
						ranges={[]}
						placeholder="Fecha" oneTap className={(fieldError ? " form-input-error " : "")} name={name}
						value={value} format={format} onChange={onChange} />
					{fieldError ? <ErrorMessage>{fieldError}</ErrorMessage> : null}
				</>
			)}
		</>)
	} else {
		return (<>
			<DatePicker disabled={disabled}
				locale={Calendar}
				disabledDate={disabledDate ? afterToday() : null}
				placeholder="Fecha" oneTap className={(fieldError ? " form-input-error " : "")}
				name={name} value={value}
				format={format} onChange={onChange} />
		</>)
	}
};

export {
	DatePickerWithError
};