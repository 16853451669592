const getUserId = () => {
    return localStorage.getItem("USERID");
};

const getUserAccessToken = () => {
    return localStorage.getItem("accessToken");
};
const getUserRefreshToken = () => {
    return localStorage.getItem("refreshToken");
};

const getExpireTk = () => {
    return localStorage.getItem("expireTk");
};

const getExpireTkRefresh = () => {
    return localStorage.getItem("expireTkRefresh");
};

const estaAutenticadoPorActiveDirectory = () => {
    const auth = localStorage.getItem("ActDirec");
    return (!auth ? Boolean(auth) : false);
};

const getObtenerGD = (key) => {
    const gd = JSON.parse(localStorage.getItem("GDMDLOACTL")) || [];

    if (Array.isArray(gd)) {
        return gd.filter(g => g?.key === key) || [];
    }
    return [];
}

export default {
    getUserId,
    getUserAccessToken,
    getUserRefreshToken,
    estaAutenticadoPorActiveDirectory,
    getExpireTk,
    getExpireTkRefresh,
    getObtenerGD
};