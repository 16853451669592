import { Input } from 'rsuite';
import ErrorMessage from './ErrorMessage';

const InputWithError = ({ isMayus = true, isDisabled = false, value = "", maxLength = "", name, onChange, onBlur, fieldError, isRequired = false }) => {

	const handleChangeName = value => (/^[a-zA-ZÀ-ÿ0-9.,-:!|¡?¿%=\s*°()><@]*$/.test(value)) && onChange(value);

	const handleBlur = value => {
		if (isMayus) {
			onChange(value.currentTarget.value.replace(/\s+/g, ' ').toUpperCase().trim());
			onBlur(value.currentTarget.value.replace(/\s+/g, ' ').toUpperCase().trim());
		} else {
			onChange(value.currentTarget.value.replace(/\s+/g, ' ').trim());
			onBlur(value.currentTarget.value.replace(/\s+/g, ' ').trim());
		}
	};

	if (isRequired) {
		return (<>
			<Input as="input" onPressEnter={handleBlur}
				autoComplete='off' maxLength={maxLength}
				disabled={isDisabled}
				className={(isMayus && " uppercase") + (fieldError ? " form-input-error " : "")}
				name={name} value={value ? value : ""} onBlur={handleBlur} onChange={handleChangeName} />
			{fieldError ? <ErrorMessage>{fieldError}</ErrorMessage> : null}
		</>)
	} else {
		return (<>
			<Input
				as="input" onBlurCapture={handleBlur}
				autoComplete='off' maxLength={maxLength}
				disabled={isDisabled} 
				className={(isMayus ? " uppercase" : "")}
				name={name}
				value={value ? value : ""} onChange={handleChangeName} />
		</>
		)
	}
};

const TextAreaWithError = ({ isMayus, isDisabled, value = "", name, onChange, onBlur, fieldError, isRequired }) => {

	const handleChangeName = value => (/^[a-zA-ZÀ-ÿ0-9.,\s*]*$/.test(value)) && onChange(value);


	const handleBlur = value => {
		if (isMayus) {
			onChange(value.currentTarget.value.replace(/\s+/g, ' ').toUpperCase().trim());
			onBlur(value.currentTarget.value.replace(/\s+/g, ' ').toUpperCase().trim());
		} else {
			onChange(value.currentTarget.value.replace(/\s+/g, ' ').trim());
			onBlur(value.currentTarget.value.replace(/\s+/g, ' ').trim());
		}
	};


	if (isRequired) {
		return (<>
			<Input as="textarea"
				disabled={isDisabled}
				onPressEnter={handleBlur}
				className={(isMayus && " uppercase") + (fieldError ? " form-input-error " : "")}
				name={name} value={value} onBlur={handleBlur} onChange={handleChangeName} />
			{fieldError ? <ErrorMessage>{fieldError}</ErrorMessage> : null}
		</>)
	} else {
		return (<>
			<Input as="textarea"
				disabled={isDisabled}
				className={(isMayus ? "uppercase" : "")}
				onBlurCapture={handleBlur} name={name} value={value} onChange={handleChangeName} />
		</>)
	}
}

export {
	InputWithError,
	TextAreaWithError,
};