import { useState } from 'react';
import { toast } from 'react-toastify';

const useToast = () => {
    const [action, setAction] = useState(false);

    const showToast = (mensaje, tipo, autoClose = 1800) => {
        setAction(true);
        return toast.loading(mensaje, {
            type: tipo,
            autoClose,
            isLoading: true,
            toastId: "toastCargando",
        });
    };

    const updateToast = (mensaje, tipo, autoClose = 1800) => {
        toast.update("toastCargando", {
            type: tipo,
            render: mensaje,
            autoClose,
            isLoading: false,
        });
        setAction(false);
    };

    return { showToast, updateToast, action};
};

export default useToast;
