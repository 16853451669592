import { useEffect } from "react"
// import Error403I from '../../assets/img/403-icon.svg'
import BMSLOGO from '../../assets/img/logo-bms.png'
import { useNavigate } from 'react-router-dom';

const Error403 = ({
    setIsNoFound = () => { }
}) => {
    const navigate = useNavigate();

    useEffect(() => {
        setIsNoFound(true);

        return () => {
            setIsNoFound(false);
        };
    }, [setIsNoFound]);

    return (
        <div className="container-error">
            <div className="container-error__card">
                <div className="container-error__card-content" style={{ marginTop: "80px" }}>
                    {/* <object className="container-error__card-icon container-error__card-icon-403" style={{ top: "-180px", width: "330px" }} data={Error403I} type="image/svg+xml"></object> */}
                    <h1 className="poppins line-height-0 container-error__card-cod">403</h1>
                    <span className="poppins container-error__card-text">Acceso Denegado</span>
                </div>
                <label>¡Oops! Parece que no tienes permiso para acceder a esta página.</label>
                <button
                    onClick={() => {
                        navigate("/inicio");
                    }}
                    className="btn-login-rediret poppins text-white font-semibold py-2 px-4 rounded -mt-6">
                    Volver al Inicio
                </button>
                {/* <div className="container-error__footer">
                    <img className="container-error__footer-logo" src={BMSLOGO} />
                </div> */}
            </div>
        </div>
    );
}

export default Error403