import { Schema } from "rsuite";
import errores from "../../core/errores";

const { NumberType, StringType, BooleanType } = Schema.Types;

const ValidarAgregarProducto = Schema.Model({
    NMBRE: StringType().isRequired(errores.ERROR("Required")),
    STOCK: NumberType().isRequired(errores.ERROR("Required")),
    PRICE: NumberType().isRequired(errores.ERROR("Required")),
    DSCRPCN: StringType().isRequired(errores.ERROR("Required")),
    GDCTGRIA: StringType().isRequired(errores.ERROR("Required")),
    IMGPRSNA: StringType().isRequired(errores.ERROR("Required")),
});

const ValidarEditarProducto = Schema.Model({
    NMBRE: StringType().isRequired(errores.ERROR("Required")),
    STOCK: NumberType().isRequired(errores.ERROR("Required")),
    PRICE: NumberType().isRequired(errores.ERROR("Required")),
    DSCRPCN: StringType().isRequired(errores.ERROR("Required")),
    GDCTGRIA: StringType().isRequired(errores.ERROR("Required")),
    IMGPRSNA: StringType().isRequired(errores.ERROR("Required")),
    ID: NumberType().isRequired(errores.ERROR("Required")),
});

export {
    ValidarAgregarProducto,
    ValidarEditarProducto
}
