
import React, { useEffect, useState } from 'react';
import DataTable, { Alignment } from 'react-data-table-component';
import CustomLoader from './CustomLoader';


const CustomTable = ({
    columns,
    data,
    onSort,
    isConvert = true,
    pagination = true,
    progressPending,
    customStyles,
    onChange = () => { },
    formValue,
    setFormValue = () => { },
    totalrows = 0
}) => {

    useEffect(() => onChange(), [formValue?.ROWS, formValue?.INIT]);

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Todas',
    };

    const convertData = (data) => {
        if (!isConvert) return data;
        let newData = data.map((obj, index) => ({ ...obj, index: (index + 1) }))
        return newData
    }

    const onChangeLocal = (pagina, total = null) => {
        setFormValue({
            ...formValue,
            INIT: ((pagina - 1) * (total ? total : formValue?.ROWS)),
            ROWS: total ? total : formValue?.ROWS
        })
    }

    const customStyles1 = {
        rows: {
          even: { backgroundColor: '#f2f2f2' },
          odd: { backgroundColor: '#ffffff' },
          onRowHover: { backgroundColor: '#e0e0e0' },
        },
    };

    return (
        <div className="my-4 table-general border rounded-lg">
            <DataTable
            // cuando acaba de cargar
                columns={columns}
                data={data}
                paginationServer={true}
                paginationServerOptions={{
                    totalRows: totalrows,
                    rowsPerPage: formValue?.ROWS,
                    onChangePage: (page) => onChangeLocal(page),
                    onChangeRowsPerPage: (currentRowsPerPage, currentPage) => onChangeLocal(currentPage, currentRowsPerPage)
                }}
                keyField='rn'
                paginationTotalRows={totalrows}
                customStyles={customStyles1}
                subHeaderAlign={Alignment.CENTER}
                pagination={pagination}                
                paginationComponentOptions={paginationComponentOptions}
                onChangeRowsPerPage={(currentRowsPerPage, currentPage) => onChangeLocal(currentPage, currentRowsPerPage)}
                onChangePage={(currentPage) => onChangeLocal(currentPage)}
                progressPending={progressPending}
                progressComponent={<CustomLoader />}
                noDataComponent={<div style={{ padding: "24px" }}>No hay registros para mostrar</div>}
            />
        </div>
    );
};

export default CustomTable;