import { forwardRef } from "react";
import { SelectWithError, SearchableSelectWithError } from "./AccepterTypes/SelectPickerAccepters";
import { InputWithError, TextAreaWithError } from "./AccepterTypes/InputAccepters";
import { DatePickerWithError } from "./AccepterTypes/DatePickerAccepters";
import { TimePickerWithError } from "./AccepterTypes/TimePickerAccepter";
import { InputNumberWithError } from "./AccepterTypes/InputNumberAccepters";
import { ImagenUpload } from "./AccepterTypes/ImageUploadAccepters";
import { Checkoud } from "./AccepterTypes/CheckoudAccepters";
import { RadioGroupWithError } from "./AccepterTypes/RadioGroupAccepters";
import { InputNumberWithErrorMin } from "./AccepterTypes/InputNumberWithErrorMin";
import { InputPaswordWithError, InputEmailWithError } from "./AccepterTypes/InputPasswordAccepters";
import { ToogleGroupWithError } from "./AccepterTypes/ToogleAccepters";

const Accepters = {
	TextInput: forwardRef((props, ref) => <InputWithError {...props} ref={ref} />),
	TextArea: forwardRef((props, ref) => <TextAreaWithError {...props} ref={ref} />),
	SelectPicker: forwardRef((props, ref) => <SelectWithError {...props} ref={ref} />),
	SearchableSelectPicker: forwardRef((props, ref) => <SearchableSelectWithError {...props} ref={ref} />),
	DatePicker: forwardRef((props, ref) => <DatePickerWithError {...props} ref={ref} />),
	Number: forwardRef((props, ref) => <InputNumberWithError {...props} ref={ref} />),
	NumberMin: forwardRef((props, ref) => <InputNumberWithErrorMin {...props} ref={ref} />),
	Imagen: forwardRef((props, ref) => <ImagenUpload {...props} ref={ref} />),
	Checked: forwardRef((props, ref) => <Checkoud {...props} ref={ref} />),
	Time: forwardRef((props, ref) => <TimePickerWithError {...props} ref={ref} />),
	RadioGroup: forwardRef((props, ref) => <RadioGroupWithError {...props} ref={ref} />),
	Password: forwardRef((props, ref) => <InputPaswordWithError {...props} ref={ref} />),
	Email: forwardRef((props, ref) => <InputEmailWithError {...props} ref={ref} />),
	Toogle: forwardRef((props, ref) => <ToogleGroupWithError {...props} ref={ref} />),
};

export default Accepters;