import React, { useState, useEffect } from 'react'
import NavMenu from './NavMenu'
import modulosProxy from '../proxy/modulosProxy'
import Spinner from './Spinner'
import Header from './Header'
import constants from '../core/constants'
import { useLocation } from 'react-router-dom'

const Layout = ({
  children,
  AppRoutes
}) => {
  const location = useLocation()
  const [modulosList, setModulosList] = useState([])
  const [loading, setLoading] = useState(true)
  const [routePath, setRoutePath] = useState({})

  useEffect(() => {
    modulosProxy.ListarSubModulos({ rows: 1000, init: 0, idmdlo: constants.ID_MODULO })
      .then(resp => setModulosList(resp.data))
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    const breadcrums = AppRoutes.find(route => ("/" + route.path) === location.pathname)
    if (breadcrums) {
      setRoutePath(breadcrums)
    }
  }, [location.pathname]);

  return (
    loading ? <Spinner mensaje="Estamos preparando todo, espere un momento.." />
      :
      <>
        <NavMenu modulosList={modulosList} />
        <section className="dashboard transition-all duration-500 relative left-0 md:left-64 min-h-screen p-6">
          <Header title={routePath?.breadcrums?.TITLE} breadcrums={routePath?.breadcrums?.breadcrums} />
          {children}
        </section>
      </>

  )
}

export default Layout