
const ENTORNOS = {
    desarrollo: "Development",
    pruebas: "Staging",
    produccion: "Production"
}

const CARPETA_DESPLIEGUE = 'log-web/'
const CARPETA_LOGIN = "login-web/"

/*AL MOMENTO DE DESPLEGAR CAMBIAR AQUI*/

const ENTORNO_DESPLIEGUE = ENTORNOS.produccion;

const ID_MODULO = '9'

export default {
    ENTORNO_DESPLIEGUE,
    CARPETA_DESPLIEGUE,
    CARPETA_LOGIN,
    ID_MODULO
}