const ChangePicker = (fecha = null, simbolo = "-") => {
    let date = fecha != null ? new Date(fecha) : new Date();
    let day = (date.getDate() + "").padStart(2, "0");
    let month = (date.getMonth() + 1 + "").padStart(2, "0");
    let year = (date.getFullYear() + "").padStart(2, "0");
    return `${year}${simbolo}${month}${simbolo}${day}`.toString();
};

export default {
    ChangePicker
};