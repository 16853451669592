import React from "react";

const datetime = ({ fecha, hora, color = '#FFFFFF' }) => {

    var time = new Date();

    const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]

    var showdate = new Date();
    var displaytodaysdate = showdate.getDate() + ' de ' + months[showdate?.getMonth()] + ' del ' + showdate.getFullYear();
    return (
        <div>
            {fecha && <p className="poppins text-xl text-black font-light inline-block" style={{ color: color }}>{displaytodaysdate}</p>}
            {hora && <p className="poppins text-xl text-black text-base" style={{ color: color }}>{time.toLocaleString('en-US', { hour: 'numeric', minute: "numeric", hour12: true })}</p>}
        </div>
    )
}

export default datetime;