import React, { useEffect, useState } from 'react';
import Router from './route/Router';
import './stylesGlobal.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import jwt_decode from "jwt-decode";
import Error401 from './views/Home/401';

library.add(fas);

const App = () => {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(true);

  useEffect(() => {
    const autenticando = async () => {
      setLoading(true);

      try {
        const currentUrl = await new URL(window.location.href);
        const searchParams = await new URLSearchParams(currentUrl.search);

        const accessToken = searchParams.get('accessToken');
        const refreshToken = searchParams.get('refreshToken');
        const idmdlo = searchParams.get('idmdlo');

        if (![undefined, null, ""].includes(accessToken)
          || ![undefined, null, ""].includes(refreshToken)
        ) {

          const decoded = await jwt_decode(accessToken);
          const decodedRefresh = await jwt_decode(refreshToken);

          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('refreshToken', refreshToken);
          localStorage.setItem('expireTk', decoded?.exp);
          localStorage.setItem('expireTkRefresh', decodedRefresh?.exp);
          localStorage.setItem('ruc', decoded?.RUC);
          localStorage.setItem('idmdlo', idmdlo);

          setStatus(true);

        }
        else {
          if (localStorage.getItem('accessToken') || localStorage.getItem('refreshToken')) {
            setLoading(false);
            return null
          }
          
          localStorage.clear();
          setStatus(false);
        }
      }
      catch (error) {
        setStatus(false);
      }
      finally {
        setLoading(false);
      }
    }

    autenticando();
  }, [])


  return (
    <>
      {
        loading ?
          <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
          :
          status ?
            <Router />
            :
            <Error401 />
      }
    </>

  );
}


export default App;