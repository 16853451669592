import config from '../config.json'
import constants from '../core/constants';

const getAuthUrl = () => {
    return config[constants.ENTORNO_DESPLIEGUE]?.["login-web"];
}

const getSegUrl= () => {
    return config[constants.ENTORNO_DESPLIEGUE].ApiUrls?.["seg-web"];
};

const getRetUrl= () => {
    return config[constants.ENTORNO_DESPLIEGUE].ApiUrls?.["ret-web"];
};

const getApiUrlView = (view) => {
    return config[constants.ENTORNO_DESPLIEGUE].ApiUrls[view];
}

export default {
    getAuthUrl,
    getRetUrl,
    getApiUrlView,
    getSegUrl,
};