import obtenerGDProxy from "../../proxy/obtenerGDProxy";

const useGDato = async (cgdto) => {
    let array = [];
    const res = await obtenerGDProxy.obtenerSelected({
        cgdto:
            cgdto?.join(",")
    });
    
    if (res?.status == 200 && res?.data?.length > 0) {
        array = res?.data;
    }

    return array;
}

export default {
    useGDato
};