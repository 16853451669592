import { MaskedInput, InputGroup } from 'rsuite';
import ErrorMessage from './ErrorMessage';

const TimePickerWithError = (
	{ valueDate = "", name = "", postfix = "", onChange, fieldError, isRequired }
) => {

	const handleOnChange = (value) => onChange(value.trim());

	const styles = {
		width: "auto"
	}

	const options = [
		{
			name: 'Date with time',
			mask: [/\d/, /\d/, ':', /[0-5]/, /[0-9]/,],
			placeholder: 'hh:mm hrs'
		},
		{
			name: 'Date with time',
			// /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/
			mask: [/[0-2]/, /\d/, ':', /[0-5]/, /[0-9]/,],
			placeholder: 'hh:mm AM - PM'
		}
	];

	if (isRequired) {
		return (<>
			<InputGroup style={styles} className={(fieldError ? " form-input-error " : "")}>
				<MaskedInput
					value={valueDate}
					guide={false}
					onChange={handleOnChange}
					mask={(postfix) ? options[1].mask : options[0].mask}
					placeholder={(postfix) ? options[1].placeholder : options[0].placeholder}
					name={name}
					className={(fieldError ? " form-input-error without_ampm" : "without_ampm")} style={{ width: 260 }}
				/>
				{(postfix) && (
					<InputGroup.Addon>{postfix}</InputGroup.Addon>
				)}
			</InputGroup>

			{/* <MaskedInput
				value={valueDate}
				guide={false}
				onChange={handleOnChange}
				mask={options[0].mask}
				placeholder={options[0].placeholder}
				name={name}
				className={(fieldError ? " form-input-error without_ampm" : "without_ampm")} style={{ width: 260 }}
			/> */}
			{fieldError ? <ErrorMessage>{fieldError}</ErrorMessage> : null}
		</>)
	} else {
		return (<>
			<InputGroup style={styles} className={(fieldError ? " form-input-error " : "")}>
				<MaskedInput
					value={valueDate}
					onChange={handleOnChange}
					mask={(postfix) ? options[1].mask : options[0].mask}
					placeholder={(postfix) ? options[1].placeholder : options[0].placeholder}
					name={name}
					className={(fieldError ? " form-input-error " : "")} style={{ width: 260 }}
				/>
				{(postfix) && (
					<InputGroup.Addon>{postfix}</InputGroup.Addon>
				)}
			</InputGroup>
		</>)
	}
};

export {
	TimePickerWithError,
};