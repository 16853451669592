import { Radio, RadioGroup } from 'rsuite';
import ErrorMessage from './ErrorMessage';

const RadioGroupWithError = ({ dataRadio = [], value = "", onChange, name, fieldError, isRequired }) => {

    const handleChange = (value) => onChange([{ name }] = value);

    if (isRequired) {
        return (
            <>
                <RadioGroup value={value} name={name} onChange={handleChange} inline>
                    {(dataRadio.length > 0) && (dataRadio.map((element, index) => (
                        <Radio key={index} value={element.value}>{element.label}</Radio>
                    )))}
                </RadioGroup>
                {fieldError ? <ErrorMessage>{fieldError}</ErrorMessage> : null}
            </>
        )
    } else {
        return (
            <>
                <RadioGroup name={name} onChange={handleChange} inline>
                    {(dataRadio.length > 0) && (dataRadio.map((element, index) => (
                        <Radio key={index} value={element.value}>{element.label}</Radio>
                    )))}
                </RadioGroup>
            </>
        )
    }
};

export {
    RadioGroupWithError,
};