
import CustomModal from '../customs/CustomModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ModalEliminarUsuario = (
    { openModalEliminar, toogleModalEliminar, onDelete, action }
) => {

    return (
        <CustomModal
            modalType="delete"
            handleSubmit={() => onDelete()}
            title="Cambiar Estado del Registro"
            size="sm"
            action={action}
            openModal={openModalEliminar}
            toogleModal={toogleModalEliminar}
            btnActionPrimary='Aceptar'
        >
            <div className='m-auto w-full flex justify-center flex-col items-center gap-4 mb-3'>
                <div className='w-20 h-20 rounded-full border-2 border-red-700 bg-red-50 flex justify-center items-center'>
                    <FontAwesomeIcon
                        icon="fa-solid fa-refresh"
                        className='text-red-800 text-3xl' />
                </div>
                <p className='text-base poppins'>¿Seguro que deseas cambiar el estado del registro?</p>
            </div>
        </CustomModal>
    );
}

export default ModalEliminarUsuario