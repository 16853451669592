import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCollapse } from "react-collapsed";
import constants from '../core/constants';
import Logo from "./logo";

const Collapse = ({ item }) => {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

    return (
        <>
            <button {...getToggleProps()} className="whitespace-nowrap w-full text-gray-600 hover:text-blue-500 transition-all duration-500 text-left rounded-lg flex gap-4 items-start ml-1.5">
                <FontAwesomeIcon icon={item.icon} className="text-xl" />
                <span className={`w-full uppercase poppins font-medium`}>{item.dscrpcn}</span>
                <div className="ml-2 overflow-hidden w-7">
                    {isExpanded ? <FontAwesomeIcon icon="fa-solid fa-angle-up" className="text-gray-500" /> :
                        <FontAwesomeIcon icon="fa-solid fa-chevron-down" className="text-gray-500" />}
                </div>
            </button>
            <ul {...getCollapseProps()} className="mt-2 ml-6 mr-4 flex flex-col" key={item.id + 1}>
                {item.subitems.map((subitem, index) =>
                    <li key={`${item.id}_1${index}`}  >
                        <FontAwesomeIcon icon={subitem.ICON} className="text-xl" />
                        <button key={index} className="w-full py-3 rounded-md mb-2" onClick={() => { }}>
                            <span className="uppercase links_name poppins text-gray-700 font-medium">{subitem.DSCRPCN}</span>
                        </button>
                    </li>
                )}
            </ul>
        </>
    );
};


const NavMenu = ({ modulosList }) => {

    let BreadcrumbsNavBar = modulosList.map((item) => {
        return {
            id: item.id,
            url: `${constants.CARPETA_DESPLIEGUE}${item.url}`,
            dscrpcn: item.dscrpcn,
            icon: item.icon,
            subitems: JSON.parse(item.items) || []
        }
    })

    const [toggle, setToggle] = useState(false);
    const navigate = useNavigate();
    const toggleNavBar = () => setToggle(!toggle);
    const handleClick = (path) => navigate(path);

    const pageItems = () => {
        return BreadcrumbsNavBar?.map((item) =>
            (item.subitems.length <= 0) ? (
                <button className="whitespace-nowrap w-full text-gray-500 hover:bg-white px-3 py-2 my-1.5 transition-all duration-500 text-left rounded-lg flex gap-4 items-center" key={item.id} onClick={() => handleClick(item.url)}>
                    <div className="min-w-[30px] h-full rounded-md text-center -mt-1">
                        <FontAwesomeIcon icon={item.icon}
                            className="text-md -ml-2" />
                    </div>
                    <span className={`w-full poppins font-medium capitalize transition-all duration-500 `.concat(toggle ? "opacity-0" : "opacity-100")}>
                        {item.dscrpcn}
                    </span>
                </button>
            ) : (
                <Collapse
                    key={item.id}
                    isOpened={false}
                    item={item}
                />
            )
        );
    }

    return (
        <>
            <nav className={`navbarC overflow-hidden fixed top-0 left-0 h-full border-r-2 border-gray-200 shadow-md pt-3 z-40 transition-all duration-500 ${toggle ? "w-20 close" : "w-64"}`}>
                <Logo letters={`transition-all duration-500 `.concat(toggle ? "opacity-0" : "opacity-100")} />
                <ul className="mt-14 flex flex-col gap-y-4 overflow-hidden px-4">
                    <li key="_1" className="w-full">
                        {BreadcrumbsNavBar ? pageItems() : <></>}
                    </li>
                </ul>
            </nav>
            <button className={`fixed ml-6 top-5 z-40 transition-all duration-500 `.concat(toggle ? "left-20" : "left-64")} onClick={() => toggleNavBar()}><FontAwesomeIcon icon="fa-solid fa-bars" className="text-xl" /></button>
        </>
    );
};

export default NavMenu;