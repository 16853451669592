import { useEffect } from "react";

const NoAutorizado = ({
    isUrl = "",
    model = ""
}) => {


    // useEffect(() => window.location.href = `${isUrl}login?returnUrl=${model}`, [])

    return <>
        No se encuentra autorizado para acceder a esta página
    </>
}

export default NoAutorizado