import React from 'react'

const Spinner = ({marginTop="", mensaje = "Esto podría tomar un tiempo.", aboveAll="", pt16="", bgScreenColor=''}) => {
	return (
		<>
			<div id="preloader">
				<div className="loader d-flex justify-content-center align-items-center">
					<img src={`${process.env.PUBLIC_URL}/logo-bms.png`} className='mx-2 my-1' alt="logo" width="35px" height="35px" />
				</div>
		    </div>
		</>
	)
};

export default Spinner;