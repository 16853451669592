import React from 'react';
import DropdownUser from './DropdownUser';
import { useNavigate } from 'react-router-dom';

const Header = ({
    title,
    breadcrums = []
}) => {
    const navigate = useNavigate();

    const pageItems = () => {
        const handleNavigation = (url, event) => {
            event.preventDefault();
            if([null, undefined, ""].includes(url)) return null;
            navigate(url);
        };

        const longitud = breadcrums.length;

        return breadcrums.map((item, index) => (
            <li key={item.id} className="inline-block">
                <span
                    onClick={event => {
                        // Verifica si la URL es una función o una cadena
                        const targetUrl = typeof item.url === 'function' ? item.url(breadcrums) : item?.url;
                        handleNavigation(targetUrl, event);
                    }}
                    className="text-gray-400 no-underline px-0 hover:text-gray-900 cursor-pointer hover:no-underline hover:font-bold"
                >
                    {longitud - 1 !== index && longitud > 1 ? (
                        <>
                            {item.name}
                            <span className="mx-1 font-medium hover:text-gray-400">/</span>
                        </>
                    ) : (
                        <>{item.name}</>
                    )}
                </span>
            </li>
        ));
    };

    return (
        <div className="top-header z-30 border-b fixed top-0 flex justify-between items-center py-3 bg-white">
            <ul className='breadcrumbs flex items-center'>
                <li key="99">
                    <h1 className='text-xl poppins ml-12 font-normal lg:text-2xl'>{title}</h1>
                </li>
                <div className='ml-4'>{breadcrums ? pageItems() : <></>}</div>
            </ul>
            <div className='flex gap-x-4 items-center'>
                <label className="relative inline-flex items-center cursor-pointer">
                    <input type="checkbox" value="" className="sr-only peer" />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Modo Oscuro</span>
                </label>
                <DropdownUser />
            </div>
        </div>
    );
}
export default Header;