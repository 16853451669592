import { InputGroup, MaskedInput } from 'rsuite';
import ErrorMessage from './ErrorMessage';


const InputNumberWithError = (
    { min = 0, postfix = "",
        isSpan = true, disabled = false,
        value, name, onChange, fieldError, isRequired , maxLength}) => {

    const mask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

    const handleChangeName = value => (value >= 0 && value) ? onChange(parseInt(value)) : onChange(undefined);
    const styles = {
        width: "auto"
    }

    if (isRequired) {
        return (<>
            <InputGroup style={styles}
                autoComplete='off'
                className={(fieldError ? " form-input-error " : "")}>
                <MaskedInput
                    disabled={disabled}
                    value={value}
                    mask={mask.slice(0, maxLength)}
                    autoComplete='off'
                    name={name}
                    aria-autocomplete='none'
                    guide={false}
                    onChange={handleChangeName}
                />
                {(postfix) && (
                    <InputGroup.Addon>{postfix}</InputGroup.Addon>
                )}
            </InputGroup>
            {fieldError ? <ErrorMessage>{fieldError}</ErrorMessage> : null}
        </>)
    } else {
        return (<>
            <InputGroup style={styles} autoComplete='off'
                className={(fieldError ? " form-input-error " : "")}>
                <MaskedInput
                    disabled={disabled}
                    value={value}
                    mask={mask.slice(0, maxLength)}
                    autoComplete='off'
                    aria-autocomplete='none'
                    name={name}
                    guide={false}
                    onChange={handleChangeName}
                />
                {(postfix) && (
                    <InputGroup.Addon>{postfix}</InputGroup.Addon>
                )}
            </InputGroup>
            {(isSpan == false) ? <ErrorMessage>{fieldError}</ErrorMessage> : null}
        </>)
    }
};

export {
    InputNumberWithError,
};