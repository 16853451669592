// * DEPENDENCIES
import React from 'react'
import { Modal, Button, Loader, Animation } from 'rsuite';

// * COMPONENTS


// * FUNCTIONS



const CustomModal = (
    {
        children, openModal, toogleModal, formId = "formId", title = "Title", size, action = false,
        handleSubmit = () => { }
    }
) => {


    return (
        // <Animation.Bounce in={openModal}>
            <Modal
                backdrop={'static'}
                keyboard={false}
                size={size}
                open={openModal}
                onClose={toogleModal}>
                <Modal.Header>
                    <Modal.Title style={{ fontWeight: "bold" }} className="fontSizeTitleModal">{title}</Modal.Title>
                </Modal.Header>

                <Modal.Body className="p-1">
                    {children}
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        onClick={() => !action ? handleSubmit() : null}
                        className="w-20 h-9 btnPrimary"
                        form={formId}
                        appearance="primary">
                        {(!action) ? "Guardar" : (<Loader center={action} content="Enviando" />)}
                    </Button>
                    <Button
                        onClick={!action ? toogleModal : null}
                        appearance="subtle"
                        style={{ backgroundColor: 'black', color: 'white' }}
                    >
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
        // </Animation.Bounce>
    )
}

export default CustomModal