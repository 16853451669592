import Home from "./Home";
import Inventario from "./Inventario";

// ** Rutas por defecto
const LoginRoute = '/login'
const DefaultRoute = '/no-autorizado'

const AppRoutes = [
  ...Home,
  ...Inventario
];

export {
  AppRoutes,
  LoginRoute,
  DefaultRoute
};
