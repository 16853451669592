import format from '../helpers/formatFecha';
import { Badge, Tooltip, Whisper } from 'rsuite';
import Alignment from '../styles/Alignment';

const auditoriaTB = [
    {
        name: 'Estado',
        cell:
            row =>
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <Whisper
                        style={{ width: '100%', height: '100%' }} trigger="hover"
                        placement={"top"} controlId={"control-id-bottom"}
                        speaker={
                            <Tooltip>
                                {row.gdestdo === 'A' ? "Activo" : "Inactivo"}
                            </Tooltip>
                        }
                    >
                        <Badge color={row.gdestdo === 'A' ? "green" : "red"} />
                    </Whisper>
                </div >

    },
    {
        name: 'F. Edición',
        cell: row => {
            return (<span style={Alignment.center}>{format.ChangePicker(row.fedcn, '/')}</span>)
        },
    },
    {
        name: 'U. Edición',
        width: '150px',
        cell: row => <span style={Alignment.center}>{row.uedcn}</span>
    }
]

export default auditoriaTB