import { useEffect } from "react"
// import Error500 from '../../assets/img/500-icon.svg'
import BMSLOGO from '../../assets/img/logo-bms.png'
import { useNavigate } from 'react-router-dom';

const Error500 = ({
    setIsNoFound = () => { }
}) => {

    const navigate = useNavigate();

    useEffect(() => {
        setIsNoFound(true);

        return () => {
            setIsNoFound(false);
        };
    }, [setIsNoFound]);

    return (
        <div className="container-error">
            <div className="container-error__card">
                <div className="container-error__card-content" style={{ marginTop: "80px" }}>
                    {/* <object className="container-error__card-icon container-error__card-icon-500" style={{ top: "-220px", width: "200px" }} data={Error500I} type="image/svg+xml"></object> */}
                    <h1 className="poppins line-height-0 container-error__card-cod">500</h1>
                    <span className="poppins container-error__card-text">Fallo Interno</span>
                </div>
                <label>¡Algo salió mal! Estamos trabajando para solucionarlo.</label>
                <div
                    className="flex flex-col md:flex-row justify-center items-center gap-2 md:gap-5"
                >
                    <button
                        onClick={() => {
                            window.history.back();
                        }}
                        className="bg-gray-200 text-gray-700 poppins font-semibold py-2 px-4 rounded -mt-6">
                        Regresar
                    </button>
                    <button
                        onClick={() => {
                            navigate("/inicio");
                        }}
                        className="btn-login-rediret text-white poppins font-semibold py-2 px-4 rounded -mt-6">
                        Ir a inicio
                    </button>
                </div>
                {/* <div className="container-error__footer">
                    <img className="container-error__footer-logo" src={BMSLOGO} />
                </div> */}
            </div>
        </div>
    );

}

export default Error500
