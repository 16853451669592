import React, { createContext, useEffect, useState } from 'react';

const AppContext = createContext();

const AppContextProvider = ({ usuario, children }) => {

  return (
    <AppContext.Provider value={{
      usuario,
    }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
export { AppContext };
