import axiosService from '../services/axios.service';
import authserver from '../services/auto.service';

const url = `${authserver.getApiUrlView("login-web")}`;

const ObtenerPersona = (formValue) => {
    var cadena = `${url}usuario/listar-datos`
    return axiosService.getParams(cadena, formValue)
}

const Validator = (formValue) => {
    var cadena = `${url}usuario/validator`
    return axiosService.getParams(cadena, formValue);
}


export default {
    ObtenerPersona,
    Validator
}