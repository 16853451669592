import { SelectPicker } from 'rsuite';
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import ErrorMessage from './ErrorMessage';

const SelectWithError = ({ isDisabled = false, data = [], value = "", name, onChange, fieldError, isRequired, searchable = false }) => {
	const handleChangeName = (value) => onChange(value);

	if (isRequired) {
		return (<>
			<SelectPicker disabled={isDisabled} placeholder={(data.length < 1 ? "Sin resultados" : "Seleccione")} locale={{ noResultsText: "No hay resultados" }} value={value} name={name} data={data} className={(fieldError ? " form-input-error " : "")} searchable={searchable} onChange={handleChangeName} />
			{fieldError ? <ErrorMessage>{fieldError}</ErrorMessage> : null}
		</>)
	} else {
		return (<>
			<SelectPicker disabled={isDisabled} locale={{ noResultsText: "No hay resultados" }} placeholder={(data.length < 1 ? "Sin resultados" : "Seleccione")} value={value} name={name} data={data} searchable={searchable} onChange={handleChangeName} />
		</>)
	}
}

const SearchableSelectWithError = ({ data = [], isDisabled = false, value = "", name, onChange, fieldError, isRequired, searchable = true }) => {
	const handleChangeName = (value) => onChange(value);

	const renderMenu = menu => {
		if (data.length === 0) {
			return (
				<p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
					<SpinnerIcon spin /> Cargando...
				</p>
			);
		}

		return menu;
	};

	if (isRequired) {
		return (<>
			<SelectPicker disabled={isDisabled}
				placeholder={(data.length < 1 ? "Sin resultados" : "Seleccione")}
				locale={{ searchPlaceholder: "Buscar", noResultsText: "No hay resultados" }}
				value={value} name={name} data={data}
				style={{ width: "100%!important", display: "block!important" }}
				className={`${(fieldError ? " form-input-error " : "")}`}
				placement="bottomStart"
				renderMenu={renderMenu}
				searchable={searchable} onChange={handleChangeName} />
			{fieldError ? <ErrorMessage>{fieldError}</ErrorMessage> : null}
		</>)
	} else {
		return (<>
			<SelectPicker
				disabled={isDisabled} placeholder={(data.length < 1 ? "Sin resultados" : "Seleccione")}
				locale={{ searchPlaceholder: "Buscar", noResultsText: "No hay resultados" }}
				value={value} name={name} data={data}
				renderMenu={renderMenu}
				placement="bottomStart"
				searchable={searchable} onChange={handleChangeName} />
		</>)
	}
}

export {
	SelectWithError,
	SearchableSelectWithError
};