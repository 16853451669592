/* eslint-disable jsx-a11y/alt-text */
// * DEPENDENCIES
import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// * COMPONENTS
import DateTime from "../../components/DateTime"
import ContainerViews from "../../components/ContainerViews";
import { AppContext } from '../../context/AppContextProvider';
import { useCollapse } from "react-collapsed";


const Inicio = () => {
  const { usuario } = useContext(AppContext);
  const [datosEmpresa, setDatosEmpresa] = useState({});
  const [showOptions, setShowOptions] = useState(false);
  const cols = 3, colsResponsive = 1;
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({ defaultExpanded: false });

  const handleClick = () => {
    setShowOptions(!showOptions);
  };

  useEffect(() => {
    setDatosEmpresa(JSON.parse(localStorage.getItem("dtsemprsa")));
  }, []);

  return (
    <>
      <ContainerViews>
        <div className="grid grid-cols-12 row-start-auto gap-4">
          <div className="col-span-8">
            <div className="grid grid-cols-12 gap-4 row-auto">
              <div className="col-span-12 flex gap-2">
                <label className="text-lg"><b>Hoy,</b></label>
                <DateTime fecha={true} color="#222" />
              </div>
              <div className="col-span-12">
                <div className="relative flex flex-col justify-center px-6 py-8 rounded-lg w-full bg-green-100">
                  <h1 className="poppins text-xl max-w-[500px]">Bienvenido de vuelta, <b className="font-medium">
                    {localStorage?.getItem("username") || ""}</b>.</h1>
                  <span>¿Que planes tenemos para hoy?</span>
                  <object data={`${process.env.PUBLIC_URL}/nubes.svg`} className="absolute right-6 w-40"></object>
                </div>
              </div>
              <div className="col-span-12">
                <div className="flex justify-center flex-col w-full">
                  <h2 className="poppins text-base px-1">Más Recursos</h2>
                </div>
              </div>
              <div className="col-span-5">
                <div className="relative flex flex-col justify-center px-5 py-5 rounded-lg w-full bg-gray-50 hover:bg-gray-100 transition-all duration-300">
                  <div className="w-full text-center">
                    <FontAwesomeIcon icon="fa-solid fa-book" className="text-5xl py-5 text-gray-400" />
                  </div>
                  <div className="relative w-full flex justify-between items-center">
                    <div>
                      <h1 className="text-xl max-w-[500px]">¿Necesitas Ayuda?</h1>
                      <span className="poppins">Revisa la <b className="font-medium">Guía del Usuario</b></span>
                    </div>
                    <button onClick={handleClick} className="rounded-md text-white bg-blue-500 px-2.5 py-0.5 text-xl right-0 bottom-2">
                      <FontAwesomeIcon icon="fa-solid fa-angle-right" />
                    </button>
                    <div
                      className={`origin-top-right absolute top-0 right-0 ml-4 left-64 w-64 rounded-md shadow-lg bg-white border z-30 mt-6 mr-6 ${showOptions ? "opacity-100 scale-100 visible" : "opacity-0 scale-95 invisible"} transform transition-all duration-300`}
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="menu-button"
                      tabIndex="-1"
                    >
                      <div
                        className="py-4 flex flex-col items-start gap-y-4 px-4 z-50"
                        role="none"
                      >
                        <div className="m-auto w-full">
                          <p className="poppins text-center w-full pb-2">Guia del Usuario</p>
                          <span>Aquí encontrarás informacion relevante, problemas frecuentes y posibles soluciones.</span>
                          <div className="w-full flex justify-end">
                            <button className="btnUpdate rounded-md px-2 py-1.5 my-2">Descargar Guia</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-7 py-0">
                <label className="poppins text-base">Enlaces Recomendados <FontAwesomeIcon icon="fa-solid fa-circle-check" className="text-green-500" /></label>
                <div>
                  <ul className="grid grid-cols-12 gap-4 mt-4">
                    <li className="col-span-6 border border-gray-100 overflow-hidden rounded-md flex items-center">
                      <div className="p-4 bg-gray-50 border-gray-100 border-r">
                        <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" />
                      </div>
                      <a href="https://www.sunat.gob.pe/" className="pl-2" target="_blank">Sunat</a>
                    </li>
                    <li className="col-span-6 border border-gray-100 overflow-hidden rounded-md flex items-center">
                      <div className="p-4 bg-gray-50 border-gray-100 border-r">
                        <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" />
                      </div>
                      <a href="https://outlook.live.com/mail/about/index_es-mx.html" className="pl-2" target="_blank">Correo Electrónico</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-4 grid grid-cols-12">
            <div className="col-span-12 px-4 border-l overflow-hidden flex flex-col h-[85vh]">
              <div>
                <h2 className="text-gray-500 text-center poppins font-medium text-lg pb-4"><FontAwesomeIcon icon="fa-solid fa-circle-info" className="text-green-400 mr-2" />Información del Usuario</h2>
                <div className="relative flex justify-center items-center w-full">
                  <object data={`${usuario.imgprsna}`} className="p-2 w-28 h-28 bg-gray-100 border rounded-md overflow-hidden"></object>
                  <span className={`absolute flex items-center gap-1 -bottom-3 border ${usuario?.gdestdo == "A" ? " border-green-500 " : " border-red-500 "} bg-white px-2 py-0.5 rounded-md`}><div className={`w-2 h-2 rounded-full ${usuario?.gdestdo == "A" ? "bg-green-500" : "bg-red-500"}`} />{usuario?.gdestdo == "A" ? "Activo" : "Inactivo"}</span>
                </div>
                <div className="flex flex-col justify-center items-center py-6 border-b">
                  <div className="flex flex-col gap-2 w-full">
                    <span className="text-center poppins w-full">{usuario?.nombre}</span>
                    <div className={`sm:col-span-${colsResponsive} lg:col-span-${cols}  mt-2`}>
                      <div className="header poppins" {...getToggleProps()}>
                        {isExpanded ?
                          <div className="py-1 pl-4 text-xs font-normal text-red-700 bg-red-100 rounded-md w-36">- Ocultar Perfiles</div>
                          :
                          <div className="py-1 pl-4 text-xs font-normal text-green-700 bg-green-100 rounded-md w-36">+ Mostrar Perfiles</div>
                        }
                      </div>
                    </div>
                    <div {...getCollapseProps()} className={`col-span-12 mt-2`}>

                      {
                        usuario?.perfiles?.map((item, index) => (
                          <span key={item?.IDPRFL} className="mx-auto mt-2 flex justify-center poppins bg-gray-50 px-2 py-1 rounded-md text-blue-700"><b className="text-center font-medium">{item?.DSCRPCN}</b></span>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full pb-2">
                <h3 className="text-sm font-medium poppins my-3 ">Empresa Asociada</h3>
                <div className="flex gap-4 pb-4">
                  <div className="flex items-center h-full">
                    <div className="p-2 w-20 h-20 bg-gray-100 rounded-md overflow-hidden">
                      <object data={`${!["", null, undefined].includes(usuario?.lgoemprsa) ? `data:image/png;base64,${usuario?.lgoemprsa}` : ""}`} className="w-full h-full"></object>
                    </div>
                  </div>
                  <div className="flex flex-col gap-3">
                    <div>
                      <label className="text-left mb-1">Razón Social</label>
                      <p className="text-left poppins w-full"><b className="font-medium">{usuario?.rznscl || ""}</b></p>
                    </div>
                    <div>
                      <label className="text-left mb-1">Nro. Documento | Ruc</label>
                      <p className="text-left poppins w-full"><b className="font-medium">{usuario?.ruc || ""}</b></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="absolute bottom-0 mt-6 w-full text-center">Todos los Derechos Reservados - Elaborado por <b>BMSYSTEM</b> | 2023</footer>
      </ContainerViews>
    </>
  );
};
export default Inicio;
