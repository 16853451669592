import { Form } from "rsuite";


const FormControlCustom = ({
	className,
	label,
	name,
	maxLength,
	value = null,
	valueDate = "",
	checked = false,
	data = [],
	postfix = "",
	dataRadio = [],
	onChange = () => { },
	format = "dd-MM-yyyy",
	onBlur = () => { },
	isSpan = true,
	rowError,
	isRequired,
	readOnly = false,
	accepter,
	min = 0,
	isDisabled,
	disabled = false,
	disabledDate = false,
	text = "Respuesta Correcta",
	isMayus = true,
	onChangeBool = () => { },
}) => {

	const onChangeHandler = value => onChange(value);
	const onBlurHandler = value => onBlur(value);

	return (
		<Form.Group className={className} >
			{isRequired ? (
				<div className="col-span-12">
					<Form.ControlLabel>{label}<span className="ml-3 text-red-600">*</span></Form.ControlLabel>
				</div>
			) : (
				<div className="col-span-12">
					<Form.ControlLabel>{label}</Form.ControlLabel>
				</div>
			)}
			<Form.Control
				className={readOnly ? "w-full pointer-events-none" : "w-full"}
				name={name}
				maxLength={maxLength}
				value={![null, undefined].includes(value) ? value : null}
				data={data}
				onChange={onChangeHandler}
				onBlur={onBlurHandler}
				accepter={accepter}
				fieldError={rowError}
				errorMessage=""
				dataRadio={dataRadio}
				onChangeBool={onChangeBool}
				min={min}
				format={format}
				isSpan={isSpan}
				postfix={postfix}
				valueDate={valueDate}
				isRequired={isRequired}
				checked={checked}
				isDisabled={isDisabled}
				disabled={disabled}
				disabledDate={disabledDate}
				text={text}
				isMayus={isMayus}
			/>
		</Form.Group>

	);
};
export default FormControlCustom;