import { useState } from 'react';
import { Input, InputGroup } from 'rsuite';
import ErrorMessage from './ErrorMessage';
import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';

const InputPaswordWithError = ({ isDisabled = false, value = "", maxLength = "", name, onChange, onBlur, fieldError, isRequired }) => {
    const [visible, setVisible] = useState(false);

    const handleChangeName = value => (/^[a-zA-ZÀ-ÿ0-9.@]*$/.test(value)) && onChange(value);

    const handleBlur = value => {
        if (value.currentTarget.value.replace(/\s+/g, ' ').toUpperCase().trim() === "") return;
        onChange(value.currentTarget.value.replace(/\s+/g, ' ').trim());
        onBlur(value.currentTarget.value.replace(/\s+/g, ' ').trim());
    };

    return (
        <>
            <InputGroup
                className={(isRequired && fieldError ? " form-input-error " : "")}
            >
                <Input
                    as="input" onPressEnter={handleBlur}
                    autoComplete='off'
                    name={name}
                    disabled={isDisabled}
                    value={value ? value : ""}
                    onBlur={handleBlur} onChange={handleChangeName}
                    type={visible ? 'text' : 'password'} />
                <InputGroup.Button onClick={() => { setVisible(!visible) }}>
                    {visible ? <EyeIcon /> : <EyeSlashIcon />}
                </InputGroup.Button>
            </InputGroup>
            {isRequired && fieldError ? <ErrorMessage>{fieldError}</ErrorMessage> : null}
        </>
    )

};

const InputEmailWithError = ({ isDisabled = false, value = "", maxLength = "", name, onChange, onBlur, fieldError, isRequired }) => {
    const handleChangeName = value => (/^[a-zA-ZÀ-ÿ0-9.]*$/.test(value)) && onChange(value);

    const handleBlur = value => {
        onChange(value.currentTarget.value.replace(/\s+/g, ' ').toUpperCase().trim());
        onBlur(value.currentTarget.value.replace(/\s+/g, ' ').toUpperCase().trim());
    };

    return (
        <>
            <InputGroup
                className={(isRequired && fieldError ? " form-input-error " : "")}
            >
                <Input
                    as="input" onPressEnter={handleBlur}
                    autoComplete='off'
                    disabled={isDisabled}
                    className={"uppercase " + (fieldError ? " form-input-error " : "")}
                    name={name} value={value ? value : ""} onBlur={handleBlur} onChange={handleChangeName}
                />
            </InputGroup>
            {isRequired && fieldError ? <ErrorMessage>{fieldError}</ErrorMessage> : null}
        </>
    )

};

export {
    InputPaswordWithError,
    InputEmailWithError
};