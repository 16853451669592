import { useEffect, useState } from 'react'
// ** React Imports
import { Suspense, Fragment } from 'react'
import { toast } from 'react-toastify'
// ** Utils
import userservice from '../services/user.service'
import usuarioProxy from '../proxy/autenticacionProxy'
import gdProxy from "../components/hooks/useGDato";

// ** Router Components
import { BrowserRouter as AppRouter, Route, Routes, Navigate } from 'react-router-dom'
import NoAutorizado from "../views/Home/NoAutorizado"
import authService from '../services/auto.service';

// ** Routes & Default Routes
import { DefaultRoute } from './AppRoutes'

// ** Layouts
import Layout from '../components/Layout';

import Spinner from '../components/Spinner'
// ** Styles
import AppContextProvider from '../context/AppContextProvider';
import { AppRoutes } from './AppRoutes';
import constants from '../core/constants'
import Error500 from '../views/Home/500'
import Error404 from '../views/Home/404'
import Error403 from '../views/Home/403'
import Error401 from '../views/Home/401'

const Router = () => {
    const [usuario, setUsuario] = useState({});

    useEffect(() => {
        const obtenerUsuario = async () => {
            await usuarioProxy.ObtenerPersona({
                INDCR: true
            })
                .then(response => {
                    setUsuario({
                        ...response.data,
                        perfiles: JSON.parse(response.data.perfiles) || []
                    })
                    localStorage.setItem('username', response?.data?.nombre)
                })
                .catch(error => {
                    toast.error("Error al obtener datos de usuario")
                })
        }

        const obtenerGrupoDato = async () => {
            const res = await gdProxy.useGDato(['GDCTGRIA']);
            localStorage.setItem('GDMDLOACTL', JSON.stringify(res))
        }


        obtenerUsuario();
        obtenerGrupoDato();
    }, [])

    // ** Current Active Item
    const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')

    /**
     ** Final Route Component Checks for Login & User Role and then redirects to the route
     */
    const FinalRouteContent = ({ route }) => {
        const [isValidated, setIsValidated] = useState(false);
        const [authError, setAuthError] = useState(false);
        const [status, setStatus] = useState({
            status: 0,
            isAuthenticated: false,
            actions: []
        });

        useEffect(() => {
            const fetchData = async () => {
                try {
                    let pathToArr = route.pathTo.split("/");
                    let isAuthenticated = pathToArr.includes("inicio");

                    if (!pathToArr.includes("no-autorizado") && !pathToArr.includes("error") && !pathToArr.includes("inicio")) {
                        const response = await usuarioProxy.Validator({
                            PATH: route.pathTo ? route.pathTo : null,
                            IDMDLO: constants.ID_MODULO
                        });

                        setStatus({
                            status: response.status,
                            isAuthenticated: response.data?.success,
                            actions: JSON.parse(response.data?.data?.acciones || "[]")
                        })

                        isAuthenticated = response.data && response.status === 200;
                    }

                    setAuthError(!isAuthenticated);

                } catch (error) {
                    setStatus({
                        status: error?.response?.status || 500,
                        isAuthenticated: error?.data?.success || false,
                        actions: []
                    })
                    setAuthError(true);
                }
                finally {
                    setIsValidated(true);
                }
            };

            fetchData();
        }, []);

        if (!isValidated) {
            return <Spinner />;
        }

        if (authError) {
            switch (status.status) {
                case 401:
                    return <Error401 />;
                case 403:
                    return <Error403 />;
                case 404:
                    return <Error404 />;
                case 500:
                    return <Error500 />;
                default:
                    return <Error500 />;
            }
        }

        return { ...route.element, props: { ...route.element.props, actions: status.actions } };
    };

    // ** Return Route to Render

    const ResolveRoutes = () => {
        return (
            <>
                {AppRoutes.map(route => {
                    return (
                        <Route
                            key={route.path}
                            path={route.path}
                            exact={route.exact === true}
                            element={<Fragment key={route.path}><FinalRouteContent route={route} /></Fragment>}
                        />
                    );
                })}
            </>
        );
    };

    return (
        <AppRouter basename={baseUrl}>
            <AppContextProvider usuario={usuario}>
                <Layout AppRoutes={AppRoutes}>
                    <Suspense fallback={<Spinner />} >
                        <Routes>
                            <Route
                                exact
                                path='/'
                                element={userservice.getUserAccessToken() ? <Navigate to={DefaultRoute} /> :
                                    <NoAutorizado isUrl={authService.getAuthUrl()} model={authService.getApiUrlView("SeguridadUrl")} />
                                }
                            />

                            {ResolveRoutes()}

                            {/* NotFound Error page */}
                            <Route path='*' element={<Error404 />} />
                        </Routes>
                    </Suspense>
                </Layout>
            </AppContextProvider>
        </AppRouter>
    )
}

export default Router
