import { Uploader } from 'rsuite';
import ErrorMessage from './ErrorMessage';


const ImagenUpload = ({ isDisabled = false, value = "", name, onChange, onBlur, fieldError, isRequired }) => {

    const handleChangeName = value => onChange(value);

    if (isRequired) {
        return (<>
            <input type="file" name={name} accept="image/*" onChange={onChange} value={value}/>
            {/* <Uploader name={name} multiple={false} maxPreviewFileSize={1}  locale={{upload: "Subir Imagen"}}  listType="text" 
            accept="image/*"  action="//jsonplaceholder.typicode.com/posts/"
                value={value}  onChange={handleChangeName}
                disabled={isDisabled} />
            {fieldError ? <ErrorMessage>{fieldError}</ErrorMessage> : null} */}
        </>)
    } else {
        return (<>
            {/* <Uploader name={name} listType="text"  maxPreviewFileSize={1} locale={{upload: "Subir Imagen", error: "Fallo al subir imagen"}}  accept="image/*" action="//jsonplaceholder.typicode.com/posts/"
                value={value}  onChange={handleChangeName}
                disabled={isDisabled} /> */}
        </>)
    }
};

export {
    ImagenUpload,
};