import axiosService from '../services/axios.service';
import authserver from '../services/auto.service';

const url = `${authserver.getApiUrlView("log-web")}`;

const ListarProductos = (formValue) => {
    var cadena = `${url}inventario/listar-productos`
    return axiosService.getParams(cadena, formValue)
}

const ObtenerProducto = (formValue) => {
    var cadena = `${url}inventario/obtener-producto`
    return axiosService.getParams(cadena, formValue)
}

const InsertarProducto = (formValue) => {
    var cadena = `${url}inventario/insertar`
    return axiosService.post(cadena, formValue)
}

const ActualizarProducto = (formValue) => {
    var cadena = `${url}inventario/actualizar`
    return axiosService.post(cadena, formValue)
}

const EliminarProducto = (formValue) => {
    var cadena = `${url}inventario/eliminar`
    return axiosService.post(cadena, formValue)
}

export default {
    ListarProductos,
    ObtenerProducto,
    InsertarProducto,
    ActualizarProducto,
    EliminarProducto
}