import { IconButton, Whisper, Tooltip } from 'rsuite';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IconButtonCustom = ({
    TooltipMsg = "VER",
    onClick = () => { },
    className = "",
    icon = "fa-solid fa-eye"
}) => {
    return (
        <Whisper
            style={{ width: '100%', height: '100%' }} trigger="hover"
            placement={"top"} controlId={"control-id-bottom"}
            speaker={
                <Tooltip>{TooltipMsg}</Tooltip>
            }
        >
            <IconButton
                className={className}
                style={{ margin: "0 5px" }}
                appearance="subtle"
                onClick={() => onClick()}
                icon={<FontAwesomeIcon icon={icon} />}
            />
        </Whisper>
    )
}

export default IconButtonCustom